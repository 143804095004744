<template>
  <div
    v-if="deliveryFee && deliveryServiceId == 2"
    class="delivery-fee grey lighten-3 rounded pa-3"
  >
    <div class="text-h4 primary--text font-weight-bold">
      {{ $t("deliveryFee.listTitle") }}
    </div>
    <div class="delivery-fee-list" v-if="showList">
      <div
        class="black--text text-caption"
        v-for="(deliveryFeeRow, index) in deliveryFee.deliveryFeeRows"
        :key="deliveryFeeRow.deliveryFeeRowId"
        v-html="deliveryFeeRow.shortDescr"
        :class="index == indexDeliveryFeeRange ? 'font-weight-bold' : ''"
      ></div>
      <div class="caution mt-3" v-html="$t('deliveryFee.caution')"></div>
    </div>

    <div
      v-else-if="deliveryFee.deliveryFeeRows.length > 0 && discountText"
      :class="optionClass"
    >
      <span class="primary--text font-weight-bold">{{ discountText }}</span>
      <v-progress-linear :value="ammountToReachDiscount"></v-progress-linear>
      <div
        v-for="deliveryFeeRow in this.deliveryFee.deliveryFeeRow"
        :key="deliveryFeeRow.deliveryFeeRowId"
        class="d-flex align-items"
      >
        <v-progress-linear
          :value="actualPercentaceToMaxGoal"
        ></v-progress-linear>
        <div class="pallino-colorato"></div>
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.delivery-fee {
  .delivery-fee-list,
  .discount {
    font-size: 12px;
  }
  .discount {
    margin: 3px 0;
  }
  .navbar-delivery-fee {
    font-size: 11px;
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";

import { mapState, mapGetters } from "vuex";

export default {
  name: "DeliveryFee",
  data() {
    return {
      deliveryFee: null,
      minimunAmmountToDiscount: 15,
      ammountToReachDiscount: 0
    };
  },
  props: {
    showList: { type: Boolean, default: true },
    optionClass: { type: String, default: "discount grey lighten-3" }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      deliveryServiceId: "cart/getDeliveryServiceId"
    }),
    discountText() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.deliveryFee.deliveryFeeRows[i].range > this.cart.totalPrice
          ) {
            let amountToDiscount =
              this.deliveryFee.deliveryFeeRows[i].range - this.cart.totalPrice;
            if (amountToDiscount < this.minimunAmmountToDiscount) {
              // se meno di minimunAmmountToDiscount € non mostriamo
              this.$emit("changeShowingDeliveryFee", true);
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.ammountToReachDiscount =
                ((this.minimunAmmountToDiscount - amountToDiscount) * 100) /
                this.minimunAmmountToDiscount;
              return this.$t("deliveryFee.amountToDiscount", {
                amount: this.$n(amountToDiscount, "currency")
              });
            }
          }
        }
      }
      this.$emit("changeShowingDeliveryFee", false);
      return null;
    },
    // actualPercentaceToMaxGoal() {
    //   let perc = this.maxGrossTotalGoal
    //     ? (this.cart.totalPrice * 100) / this.maxGrossTotalGoal
    //     : 0;
    //   return perc;
    // },
    // maxGrossTotalGoal() {
    //   let goal = 0;
    //   if (this.deliveryFee?.deliveryFeeRows.length > 0) {
    //     goal =
    //       this.deliveryFee.deliveryFeeRows.length > 1
    //         ? this.deliveryFee.deliveryFeeRows[
    //             this.deliveryFee.deliveryFeeRows.length - 2
    //           ].range
    //         : this.deliveryFee.deliveryFeeRows[0].range;
    //   }
    //   return goal;
    // },
    indexDeliveryFeeRange() {
      if (this.deliveryFee) {
        for (let i = 0; i < this.deliveryFee.deliveryFeeRows.length; i++) {
          if (
            this.cart.totalPrice > this.deliveryFee.deliveryFeeRows[i].range &&
            !(
              this.deliveryFee.deliveryFeeRows[i + 1] &&
              this.cart.totalPrice >
                this.deliveryFee.deliveryFeeRows[i + 1].range
            )
          ) {
            console.log(
              this.deliveryFee.deliveryFeeRows[i].range,
              this.cart.totalPrice
            );
            return i + 1;
          }
        }
      }
      return 0;
    }
  },
  methods: {
    async getDeliveryFee() {
      this.deliveryFee =
        this.deliveryServiceId == 2
          ? await DeliveryService.getDeliveryFee()
          : (this.deliveryFee = null);
    }
  },
  created() {
    this.getDeliveryFee();
  }
};
</script>
